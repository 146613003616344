<template>
  <v-layout>
    <DatabaseArticlesAppBar
      :datasetIsSelected="selected"
      :searchTableIsActive="filterActive"
      @initHeaders="initialize"
      @filterChanged="setFilter"
      @resetFilters="resetFilter"
      @deleteSelectedDatasets="openDeleteDatasets"
      @createPDF="downloadPDF"
    />

    <v-data-table
      v-model="selected"
      calculate-widths
      class="pl-1"
      height="calc(100vh - 124px - 69px)"
      fixed-header
      hide-default-footer
      :items="filteredByManufacturer"
      item-key="Artikel_ID"
      :items-per-page="100"
      :item-class="rowColor"
      :headers="datasetHeaders"
      :page.sync="page"
      :search="filter.search"
      @page-count="pageCount = $event"
      @dblclick:row="showDataset"
      :single-select="false"
      :show-select="true"
      mobile-breakpoint="300"
      style="width: 100%;"
    >
      <template #[`header.Kategorie`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchCategory" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kategorie
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchCategory" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kategorie
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchCategory" dense outlined autofocus clearable hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Artikelname`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchArticlename" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelname
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchArticlename" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelname
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlename"
              dense
              clearable
              outlined
              autofocus
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Artikelnummer`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchArticlenumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchArticlenumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlenumber"
              dense
              clearable
              autofocus
              outlined
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template #[`item.Artikelnummer`]="{ item }">
        <span class="font-weight-bold">{{ item.Artikelnummer }}</span>
      </template>

      <template #[`header.Größe`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchSize" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchSize" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchSize" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Farben`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchColor" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farbe(n)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchColor" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farbe(n)
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchColor" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Stoffe`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchFabric" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Stoff(e)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchFabric" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Stoff(e)
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchFabric" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Schnitt`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchCut" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Schnitt
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchCut" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Schnitt
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchCut" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Besonderheiten`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchFeature" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Besonderheiten
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchFeature" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Besonderheiten
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchFeature" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Kaufpreis`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchSellprice" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Preis (Kauf)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchSellprice" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Preis (Kauf)
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchSellprice" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Leihpreis`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchRentprice" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Preis (Leih)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchRentprice" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Preis (Leih)
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchRentprice" dense autofocus clearable outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Hersteller`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchManufacturer" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Hersteller
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchManufacturer" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Hersteller
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchManufacturer"
              dense
              clearable
              outlined
              autofocus
              hide-details
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="12" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-footer>

    <DeleteDatasetsDialog ref="DeleteDatasets" @confirmDeleteDatasets="deleteDatasets" @abortDeleteDatasets="abortDeleteDatasets" />
  </v-layout>
</template>

<script>
import jsPDF from "jspdf";
import DatabaseArticlesAppBar from "../../../components/appbars/DatabaseAppBar.vue";
import DeleteDatasetsDialog from "../../../components/dialogs/DeleteDatasets.vue";

export default {
  components: {
    DatabaseArticlesAppBar,
    DeleteDatasetsDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    filter: {
      search: "",
      placeholder: false,
      placeholder2: false,
    },
    tableSearch: {
      searchCategory: null,
      searchArticlename: null,
      searchArticlenumber: null,
      searchSize: null,
      searchColor: null,
      searchFabric: null,
      searchCut: null,
      searchFeature: null,
      searchSellprice: null,
      searchRentprice: null,
      searchManufacturer: null,
    },
    datasets: [],
    datasetHeaders: [],
    ausgeblendeteSpalten: [],
    hiddenColumns: [],
    page: 1,
    pageCount: 0,
    selected: [],
    DeleteDatasets: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    filteredByPlaceholder() {
      if (!this.filter.placeholder) {
        return this.datasets.filter((item) => item.Status_ID !== 3);
      } else if (this.filter.placeholder) {
        return this.datasets;
      }
    },

    filteredByPlaceholder2() {
      if (!this.filter.placeholder2) {
        return this.filteredByPlaceholder.filter((item) => item.Bestellstatus_ID !== 4 && item.Bestellstatus_ID !== 5);
      } else if (this.filter.placeholder2) {
        return this.filteredByPlaceholder;
      }
    },

    filteredByCategory() {
      if (this.tableSearch.searchCategory) {
        return this.filteredByPlaceholder2.filter((item) => item.Kategorie != null && item.Kategorie.includes(this.tableSearch.searchCategory));
      } else return this.filteredByPlaceholder2;
    },

    filteredByArticlename() {
      if (this.tableSearch.searchArticlename) {
        return this.filteredByCategory.filter((item) => item.Artikelname != null && item.Artikelname.includes(this.tableSearch.searchArticlename));
      } else return this.filteredByCategory;
    },

    filteredByArticlenumber() {
      if (this.tableSearch.searchArticlenumber) {
        return this.filteredByArticlename.filter(
          (item) => item.Artikelnummer != null && item.Artikelnummer.includes(this.tableSearch.searchArticlenumber)
        );
      } else return this.filteredByArticlename;
    },

    filteredBySize() {
      if (this.tableSearch.searchSize) {
        return this.filteredByArticlenumber.filter((item) => item.Größe != null && item.Größe.includes(this.tableSearch.searchSize));
      } else return this.filteredByArticlenumber;
    },

    filteredByColor() {
      if (this.tableSearch.searchColor) {
        return this.filteredBySize.filter((item) => item.Farben != null && item.Farben.includes(this.tableSearch.searchColor));
      } else return this.filteredBySize;
    },

    filteredByFarbic() {
      if (this.tableSearch.searchFabric) {
        return this.filteredByColor.filter((item) => item.Stoffe != null && item.Stoffe.includes(this.tableSearch.searchFabric));
      } else return this.filteredByColor;
    },

    filteredByCut() {
      if (this.tableSearch.searchCut) {
        return this.filteredByFarbic.filter((item) => item.Schnitt != null && item.Schnitt.includes(this.tableSearch.searchCut));
      } else return this.filteredByFarbic;
    },

    filteredByFeature() {
      if (this.tableSearch.searchFeature) {
        return this.filteredByCut.filter((item) => item.Besonderheiten != null && item.Besonderheiten.includes(this.tableSearch.searchFeature));
      } else return this.filteredByCut;
    },

    filteredByPriceSell() {
      if (this.tableSearch.searchSellprice) {
        return this.filteredByFeature.filter((item) => item.Kaufpreis != null && item.Kaufpreis.includes(this.tableSearch.searchSellprice));
      } else return this.filteredByFeature;
    },

    filteredByPriceRent() {
      if (this.tableSearch.searchRentprice) {
        return this.filteredByPriceSell.filter((item) => item.Leihpreis != null && item.Leihpreis.includes(this.tableSearch.searchRentprice));
      } else return this.filteredByPriceSell;
    },

    filteredByManufacturer() {
      if (this.tableSearch.searchManufacturer) {
        return this.filteredByPriceRent.filter((item) => item.Hersteller != null && item.Hersteller.includes(this.tableSearch.searchManufacturer));
      } else return this.filteredByPriceRent;
    },

    filterActive() {
      if (
        this.tableSearch.searchCategory ||
        this.tableSearch.searchArticlename ||
        this.tableSearch.searchArticlenumber ||
        this.tableSearch.searchSize ||
        this.tableSearch.searchColor ||
        this.tableSearch.searchFabric ||
        this.tableSearch.searchCut ||
        this.tableSearch.searchFeature ||
        this.tableSearch.searchSellprice ||
        this.tableSearch.searchRentprice ||
        this.tableSearch.searchManufacturer
      ) {
        return true;
      } else return false;
    },
  },

  methods: {
    setFilter(newfilter) {
      this.filter = Object.assign({}, newfilter);
    },

    resetFilter() {
      this.tableSearch.searchCategory = null;
      this.tableSearch.searchArticlename = null;
      this.tableSearch.searchArticlenumber = null;
      this.tableSearch.searchSize = null;
      this.tableSearch.searchColor = null;
      this.tableSearch.searchFabric = null;
      this.tableSearch.searchCut = null;
      this.tableSearch.searchFeature = null;
      this.tableSearch.searchSellprice = null;
      this.tableSearch.searchRentprice = null;
      this.tableSearch.searchManufacturer = null;
    },

    resetSelected() {
      this.selected = [];
    },

    showDataset(event, { item }) {
      this.$router.push({
        path: `/wws/database/${this.$route.meta.request}/dataset/show/${item.Artikel_ID}`,
      });
    },

    async initialize() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/tableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.hiddenColumns = this.ausgeblendeteSpalten.map((item) => item.Spalte);

      this.resetFilter();

      this.initializeContent();
    },

    async initializeContent() {
      const response = await fetch(`/api/database/${this.$route.meta.request}/datasets`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: true,
            align: "center",
            divider: true,
            class: "text--primary text-uppercase",
          };
        });
    },

    rowColor(item) {
      if (item.Status_ID !== 3) {
        if (item.Bestellstatus_ID >= 4) {
          return "bestellt";
        }
      } else if (item.Status_ID === 3) {
        return "verkauft";
      } else return "verfügbar";
    },

    //DIALOG ZUR BESTÄTIGUNG UM EINEN TERMIN ZU LÖSCHEN
    openDeleteDatasets() {
      this.$refs.DeleteDatasets.open();
    },

    //TERMIN LÖSCHEN WENN LÖSCHUNG BESTÄTIGT
    deleteDatasets() {
      const multipleDatasetDelete = this.selected;
      const amountDeleteDatasets = multipleDatasetDelete.length;
      const request = this.$route.meta.request;

      multipleDatasetDelete.forEach(async function(dataset) {
        const datasetToDelete = {};
        const datasetHistoryToDelete = {};

        if (Object.keys(datasetToDelete).length === 0) {
          Object.assign(datasetToDelete, {
            Artikel_ID: dataset.Artikel_ID,
            Artikelname_ID: dataset.Artikelname_ID,
          });

          await fetch(`/api/database/${request}/dataset/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetToDelete),
          });
          if (dataset.Artikelname_ID !== null) {
            await fetch(`/api/database/${request}/dataset/name/available`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(datasetToDelete),
            });
          }
        }

        if (Object.keys(datasetHistoryToDelete).length === 0) {
          Object.assign(datasetHistoryToDelete, {
            Artikel_ID: dataset.Artikel_ID,
          });

          await fetch(`/api/database/${request}/dataset/events/delete`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetHistoryToDelete),
          });
        }
      });

      this.$root.actionSnackbar.show({
        amount: String(amountDeleteDatasets),
        dataset: "Artikel",
        action: "gelöscht",
      });
      this.DeleteDatasets = false;
      this.selected = [];
      this.initialize();
    },

    //LÖSCHEN ABBRECHEN
    abortDeleteDatasets() {
      this.DeleteDatasets = false;
    },

    downloadPDF() {
      const initialdata = this.filteredByManufacturer;

      initialdata.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          if (o[key] === null || o[key] === "") {
            o[key] = " ";
          }
        });
      });

      var data = initialdata.map(function(el) {
        var o = Object.assign({}, el);
        o.Check = " ";
        return o;
      });

      const initialheaders = this.datasetHeaders.map((item) => item.text);
      initialheaders.push("Check");
      var headers = initialheaders;

      const dateandtime = new Date();
      const currentdate = dateandtime.toLocaleDateString("de-DE");

      if (headers.length > 6) {
        var doc = new jsPDF({
          orientation: "landscape",
          floatPrecision: "smart",
        });
        doc.table(5, 5, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 1,
        });
        doc.save("Inventurliste Artikel - Stand " + currentdate + ".pdf");
      } else {
        var doc = new jsPDF({
          orientation: "portrait",
          floatPrecision: "smart",
        });
        doc.table(5, 5, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 1,
        });
        doc.save("Inventurliste Artikel - Stand " + currentdate + ".pdf");
      }

      this.initialize();
    },
  },
};
</script>

<style>
.verkauft {
  background: #ef5350 !important;
}

.bestellt {
  background: #ffb74d !important;
}

.verfügbar {
  background: white !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  cursor: pointer;
  background: #ddddddc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.verkauft:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ef5350c5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.bestellt:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ffb74dc5 !important;
}
</style>
